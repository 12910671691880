<template>
    <div>
        <hr />
        <b-tabs v-model="activeTab" content-class="mt-3">
            <b-tab title="Grunddata">
                <b-form @submit.prevent="onSubmit">
                    <b-form-group id="input-group-name"
                                  label="Namn"
                                  label-for="input-name">
                        <b-form-input id="input-name"
                                      v-model="form.name"
                                      type="text"
                                      placeholder="Ange namn"
                                      :disabled="isSubmitting"
                                      autocomplete="off"
                                      aria-describedby="input-name-help-block"
                                      required />
                        <b-form-text id="input-name-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('Name')" :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group id="input-group-isActive"
                                  label="Aktiv"
                                  label-for="input-isActive">
                        <b-form-checkbox id="input-isActive"
                                         v-model="form.isActive"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-isActive-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsActive')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>

                    <b-alert :show="validationByKey('Other').length > 0"
                             variant="danger">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Other')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>

                    <b-alert :show="successMessage.length > 0"
                             variant="success"
                             class="mb-0 mt-4">
                        <h6 class="mb-0 text-center">
                            <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                        </h6>
                    </b-alert>

                    <b-button v-b-tooltip.hover
                              variant="primary"
                              type="submit"
                              class="float-right mt-4"
                              :class="{ 'btn-block': id }"
                              :disabled="isSubmitting"
                              :title="isSubmitting ? 'Sparar...' : ''">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin />
                        Spara
                    </b-button>
                </b-form>
            </b-tab>
            <b-tab v-if="isEditMode" title="Logotyper" lazy>
                <h5>Stor logotyp (280x54)</h5>
                <ManageFile :id="form.logotypeFileId"
                            :entity-type="13"
                            :entity-id="form.id"
                            :fileType="1"
                            @update:updateData="refreshOrganizationLogotypeId" />
                <hr />
                <h5>Liten logotyp (80x58)</h5>
                <ManageFile :id="form.smallLogotypeFileId"
                            :entity-type="13"
                            :entity-id="form.id"
                            :fileType="2"
                            @update:updateData="refreshOrganizationLogotypeId" />
            </b-tab>
            <!--<b-tab v-if="isEditMode" title="Användare" lazy></b-tab>-->
        </b-tabs>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { get, post } from '@/helpers/api';
    import ManageFile from '@/components/file/ManageFile';

    export default {
        name: 'SaveOrganization',
        components: {
            ManageFile
        },
        props: {
            id: Number
        },
        data() {
            return {
                activeTab: 0,
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                form: {
                    id: null,
                    name: '',
                    isActive: true
                }
            };
        },
        computed: {
            isEditMode() {
                return this.form.id > 0;
            }
        },
        methods: {
            ...mapActions('user', ['getUser']),
            refreshOrganizationLogotypeId() {
               this.getUser();
            },
            async getData() {
                await get('Organization', `Get/${this.form.id}`)
                    .then((x) => {
                        this.form = x.data;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });
            },
            async onSubmit() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;
                // Post.
                await post('Organization', 'Save', this.form)
                    .then((x) => {
                        this.form.id = x.data.entityId;
                        this.successMessage = `Sparade ${this.form.name}`;
                        this.validationErrors = x.data.errors;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });
                this.isSubmitting = false;

                // On created.
                if (!this.validationErrors && this.form.id) this.onCreated();
            },
            onCreated() {
                this.hasSavedSuccessfullly = true;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: null,
                    name: '',
                    isActive: false
                };
                this.validationErrors = null;
            }
        },
        async mounted() {
            // Get organization if necessary.
            this.form.id = this.id;

            if (this.isEditMode)
                await this.getData();
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
